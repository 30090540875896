import { Navbar } from "react-bootstrap";

function MyHeader() {
  return (
    <header>
      <Navbar>
        <Navbar.Brand className="ms-4">
          <img src={process.env.PUBLIC_URL + "/img/KDP_logo.png"} alt="logo" />
        </Navbar.Brand>
      </Navbar>
    </header>
  );
}
export default MyHeader;
