import { parse, toSeconds } from "iso8601-duration";
import { format, parseISO } from "date-fns";

function dateConverter(ISOdate) {
  const date = parseISO(ISOdate);
  return format(date, "MM/dd/yy");
}

function convertToExcelDuration(isoDuration) {
  if (!isoDuration) {
    return "-";
  }
  const duration = parse(isoDuration);
  const totalSeconds = parseInt(toSeconds(duration));

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const excelDuration = `${String(hours).padStart(2, "0")}:${String(
    minutes
  ).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;

  return excelDuration;
}

const calculateProgress = function (sectionProgress) {
  let p = 0;
  sectionProgress.forEach((point) => {
    p += point;
  });
  const progress = Math.round((p / sectionProgress.length) * 100);
  return progress;
};

function calculateTotProgress(
  section1,
  section2,
  section3,
  section4,
  section5
) {
  let totLength =
    section1.length +
    section2.length +
    section3.length +
    section4.length +
    section5.length;
  let completed = 0;
  section1.forEach((point) => {
    completed += point;
  });
  section2.forEach((point) => {
    completed += point;
  });
  section3.forEach((point) => {
    completed += point;
  });
  section4.forEach((point) => {
    completed += point;
  });
  section5.forEach((point) => {
    completed += point;
  });
  const progress = Math.round((completed / totLength) * 100);
  return progress;
}

export function formatUsers(data) {
  const list = [];
  data.forEach((u) => {
    const progressData = JSON.parse(u.Suspend);
    let totalProgress = calculateTotProgress(
      progressData.conveniencePoi,
      progressData.drugPoi,
      progressData.dollarPoi,
      progressData.groceryPoi,
      progressData.massPoi
    );
    let completition = u.competed || u.score === 500 ? true : false;
    const user = {
      id: u._id,
      name: u.name.split(",").reverse().join(" "),
      email: u.lastName,
      score: u.score,
      badges: u.badge,
      time: u.time ? convertToExcelDuration(u.time) : "n.a.",
      //u.competed per typo nel backend
      completed: completition,
      date: u.completitionDate
        ? dateConverter(u.completitionDate)
        : completition
        ? "n.a."
        : "-",
      progress: {
        convenience: calculateProgress(progressData.conveniencePoi),
        drug: calculateProgress(progressData.drugPoi),
        dollar: calculateProgress(progressData.dollarPoi),
        grocery: calculateProgress(progressData.groceryPoi),
        mass: calculateProgress(progressData.massPoi),
        total: totalProgress,
      },
    };
    list.push(user);
  });
  return list;
}
