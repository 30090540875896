import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import ProgressTable from "./components/ProgressTable";
import { useState } from "react";
import LoginForm from "./components/Login";
import MyHeader from "./components/MyHeader";

function App() {
  const [isLogged, setIsLogged] = useState(false);

  return (
    <div className="App">
      <MyHeader />
      <main>
        <h1>THE KDP GOLD STANDARD EXECUTION OLYMPIX 2023</h1>
        <h3>Progress Report</h3>
        {isLogged ? (
          <ProgressTable />
        ) : (
          <LoginForm isLogged={isLogged} setIsLogged={setIsLogged} />
        )}
      </main>
    </div>
  );
}

export default App;
