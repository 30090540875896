import { useState } from "react";
import { Button, Container, Form, Modal } from "react-bootstrap";

function LoginForm({ isLogged, setIsLogged }) {
  const [errorMessages, setErrorMessages] = useState({});
  const [showLoginModal, setShowLoginModal] = useState(false);

  // User Login info
  const database = [
    {
      username: process.env.REACT_APP_USERNAME,
      password: process.env.REACT_APP_PASSWORD,
    },
  ];

  const errors = {
    uname: "Invalid username",
    pass: "Invalid password",
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    const userData = database.find((user) => user.username === uname.value);

    if (userData) {
      if (userData.password !== pass.value) {
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setIsLogged(true);
        setShowLoginModal(false); // Chiudi il modale quando l'accesso ha successo
      }
    } else {
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  return (
    <Container
      fluid
      className="h-50 d-flex flex-column justify-content-center align-items-center"
    >
      <Button
        onClick={() => setShowLoginModal(true)}
        className="w-25 loginButton"
      >
        Log In
      </Button>
      <Modal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label>User</Form.Label>
              <Form.Control type="text" name="uname" placeholder="Email" />
              {renderErrorMessage("uname")}
            </Form.Group>
            <Form.Group controlId="password" className="mt-3">
              <Form.Label>Token</Form.Label>
              <Form.Control
                type="password"
                name="pass"
                placeholder="Password"
              />
              {renderErrorMessage("pass")}
            </Form.Group>
            <div className="text-end mt-3 w-100">
              <Button type="submit" className="w-25 loginButton">
                Log In
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Container>
  );
}
export default LoginForm;
