export async function fetchUserList() {
  try {
    const response = await fetch(`https://dlc-dev.mercuri.net/api2023`);
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed reading users list");
    }
  } catch (error) {
    if (error instanceof Error) {
    } else {
      console.log("An unknown error occurred reading users list.");
    }
  }
}

export async function deleteUser(id) {
  console.log("id:", id);
  try {
    const response = await fetch(`https://dlc-dev.mercuri.net/${id}/delete`, {
      method: "DELETE",
    });
    if (response.ok) {
      console.log(response);
      return console.log("User deleted successfully");
    } else {
      console.log(response);
      throw new Error("Failed deleting user");
    }
  } catch (error) {
    if (error instanceof Error) {
      console.log(error);
    } else {
      console.log("An unknown error occurred deleting the user");
    }
  }
}
