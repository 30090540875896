import { Button, Col } from "react-bootstrap";

function ExportButton({ activeFilter }) {
  function downloadCSVFile(csv, filename) {
    var csv_file, download_link;

    csv_file = new Blob([csv], { type: "text/csv" });

    download_link = document.createElement("a");

    download_link.download = filename;

    download_link.href = window.URL.createObjectURL(csv_file);

    download_link.style.display = "none";

    document.body.appendChild(download_link);

    download_link.click();
  }

  function htmlToCSV(html, filename) {
    var data = [];
    var rows = document.querySelectorAll("table tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length - 1; j++) {
        row.push(cols[j].innerText);
      }

      data.push(row.join(","));
    }

    downloadCSVFile(data.join("\n"), filename);
  }

  function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear().toString().slice(-2);
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    return `${year}.${month}.${day}`;
  }

  const today = getCurrentDate();

  return (
    <Col xs={12}>
      <Button
        id="exportButton"
        onClick={() => {
          const html = document.querySelector("table").outerHTML;
          htmlToCSV(html, `Progress_Report-${today}.csv`);
        }}
        disabled={activeFilter ? true : false}
        className="w-100 mb-4"
      >
        Download .csv (Excel compatible table)
      </Button>
    </Col>
  );
}

export default ExportButton;
