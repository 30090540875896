import { useEffect, useState } from "react";
import {
  Table,
  Spinner,
  Modal,
  Button,
  Form,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { formatUsers } from "../mainFunctions/helpers";
import { deleteUser, fetchUserList } from "../mainFunctions/fetches";
import ExportButton from "./ExportButton";

function ProgressTable({ order }) {
  const [allUsers, setAllUsers] = useState(null);
  const [users, setUsers] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [filter, setFilter] = useState("");
  const [activeFilter, setActiveFilter] = useState(false);

  const getUsers = async () => {
    const userList = await fetchUserList();
    setAllUsers(formatUsers(userList));
    setUsers(formatUsers(userList));
    setLoading(false);
  };

  const delUser = async () => {
    await deleteUser(selectedUser);
    setSelectedUser(null);
    getUsers();
  };

  const handleShowDelete = (id) => {
    setShowDelete(true);
    setSelectedUser(id);
  };
  const handleCloseDelete = () => {
    setShowDelete(false);
    setSelectedUser(null);
  };
  const handleDelete = () => {
    setLoading(true);
    handleCloseDelete();
    delUser();
  };

  const handleFilterChange = (e) => {
    e.preventDefault();
    setFilter(e.target.value);
  };

  const handleFilterSubmit = (e) => {
    e.preventDefault();
    getFilteredUsers();
    setTimeout(2000, console.log(users))
  };

  const handleFilterReset = (e) => {
    e.preventDefault();
    setFilter("");
    setActiveFilter(false);
    setUsers(allUsers);
  };

  const getFilteredUsers = () => {
    let filteredUsers = allUsers;
    const normalizedFilter = filter.trim().toLowerCase();
    if (normalizedFilter) {
      filteredUsers = filteredUsers.filter((user) => {
        const userName = user.name.toLowerCase();
        const userEmail = user.email.toLowerCase();
        return (
          userName.includes(normalizedFilter) ||
          userEmail.includes(normalizedFilter)
        );
      });
    }
    setUsers(normalizedFilter.length > 0 ? filteredUsers : allUsers);
    setActiveFilter(normalizedFilter.length > 0 ? true : false);
  };

  useEffect(() => {
    setLoading(true);
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid>
      <Row>
        <ExportButton activeFilter={activeFilter} />
        <Col xs={12} className="mb-4">
          <h4>Search users:</h4>
          <Form onSubmit={handleFilterSubmit} onReset={handleFilterReset}>
            <Row className="g-1">
              <Col xs={9}>
                <Form.Control
                  type="search"
                  name="name"
                  placeholder="Search by name or email"
                  value={filter || ""}
                  onChange={handleFilterChange}
                />
              </Col>
              <Col xs={3} className="text-end">
                <Button type="submit" className="w-50 greyButton">
                  Search
                </Button>
                <Button type="reset" className="w-50 yellowButton">
                  Reset
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {!loading ? (
        <Table>
          <thead>
            <tr className="pink">
              <th>Name</th>
              <th>Email</th>
              <th className="text-center">Score</th>
              <th className="text-center">Badges</th>
              <th className="text-center">Progress</th>
              <th className="text-center">Completed</th>
              {/*<th className="text-center">Compl. Date</th>
              <th className="text-center">Time Spent</th>*/}
              <th>{""}</th>
            </tr>
          </thead>

          <tbody>
            {users.map((user) => {
              return (
                <tr key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td className="text-center ">{user.score}</td>
                  <td className="text-center">{user.badges}</td>
                  <td className="text-center">
                    <span>{user.progress.total}%</span>
                  </td>
                  <td className="text-center">
                    {user.completed ? "Yes" : "No"}
                  </td>
                  {/*<td className="text-center">{user.date}</td>
                  <td className="text-center">{user.time}</td>*/}
                  <td className="text-end">
                    <Button
                      className="delButton"
                      onClick={() => handleShowDelete(user.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <Modal show={showDelete} onHide={handleCloseDelete}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>This user's data and progress will be deleted.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="greyButton"
                onClick={() => setShowDelete(false)}
              >
                Close
              </Button>
              <Button
                className="delButton"
                onClick={() => {
                  handleDelete();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </Table>
      ) : (
        <Spinner />
      )}
    </Container>
  );
}

export default ProgressTable;
